<template>
  <ul class="w-full text-left pl-4 text-sm uppercase">
    <li class="flex items-center justify-center space-x-3 py-1.5">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6 text-green-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
        /></svg><span class="flex-1">Premier League</span>
    </li>
    <li class="flex items-center justify-center space-x-3 py-1.5" v-for="(league, key) in leaguesToShow" :key="key">
      <!-- <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"x
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
        /></svg> -->
        <svg fill="none" stroke="currentColor" stroke-width="1.5px" viewBox="0 0 24 24" class="h-7 w-7 text-gray-400" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd" stroke="#718096" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)">
        <circle cx="8.5" cy="8.5" r="8"/>
        <g transform="matrix(0 1 -1 0 17 0)">
        <path d="m5.5 11.5 6-6"/>
        <path d="m5.5 5.5 6 6"/></g></g></svg>
        <span class="flex-1">{{league.title}}</span>
    </li>
    <li class="mr-4">
      <button
        v-on:click="showingOnlySome = !showingOnlySome"
        class="w-full flex bg-white underline items-center justify-center text-xs p-3 px-6 font-bold uppercase  text-blue-700"
      >
        <template v-if="showingOnlySome">See all...</template>
        <template v-else>See less...</template>
      </button>
    </li>
  </ul>
</template>

<script>
import Leagues from './../data/FreeLeagues'
export default {
  name: "LeageList",
  props: ["free"],
  data() {
    return {
      showingOnlySome: true,
      leagues: []
    };
  },
  mounted() {
    this.leagues = Leagues;
  },
  computed: {
    leaguesToShow() {
      return !this.showingOnlySome ? this.leagues : this.leagues.slice(0,4);
    }
  }
};
</script>

<style></style>
