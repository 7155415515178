const Leagues = [
    { title: "World Cup" },
    { title: "European Championship" },
    { title: "Championship" },
    { title: "League One" },
    { title: "League Two" },
    { title: "FA Cup" },
    { title: "Carabao Cup" },
    { title: "Champions League" },
    { title: "Europa League" },
    { title: "Europa Conference League" },
    { title: "Bundesliga" },
    { title: "Serie A" },
    { title: "La Liga" },
    { title: "Copa del Rey" },
    { title: "Ligue 1" },
    { title: "Süper Lig" },
    { title: "Eredivisie" },
    { title: "Primeira Liga" },
    { title: "Scottish Prem" },
    { title: "Allsvenskan" },
    { title: "Eliteserien" },
    { title: "Superliga" },
    { title: "Major League Soccer" },
    { title: "USL Championship" },
    { title: "Argentine Primera División" },
    { title: "Brasileirão" },
    { title: "A-League" },
    { title: "J-League" },
    { title: "Chinese Super League" },
    { title: "Womens World Cup" }
];

export default Leagues;
